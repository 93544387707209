import RestService from '@/services/RestService'
import axios from 'axios'
import utility from '@/common/utility'
import auth from '@/common/auth'

let resourceName = 'g01'

let batchAddUrl = 'batchAdd'
let aggregationUrl = 'aggregation'
let tenderStatUrl = 'tenderStats'
let detail1TenderStatUrl = 'detail1TenderStats'
let detail2TenderStatUrl = 'detail2TenderStats'
let detail3TenderStatUrl = 'detail3TenderStats'

class G01Service extends RestService {
  batchAdd (g01Models) {
    g01Models.forEach(g01Model => {
      g01Model.generatePrimaryKey()
    })
    return axios.post(utility.getRestFullUrl(this.resourceName, batchAddUrl), g01Models, {
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  aggregation (tenderGuid, year, month) {
    return axios.get(utility.getRestFullUrl(this.resourceName, aggregationUrl), {
      params: {
        tenderGuid,
        year,
        month
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  getTenderStat (year, month, projectGuid, engineeringType = '土建') {
    return axios.get(utility.getRestFullUrl(this.resourceName, tenderStatUrl), {
      params: {
        year,
        month,
        projectGuid,
        engineeringType
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  getDetail1TenderStat (year, month, projectGuid, engineeringType) {
    return axios.get(utility.getRestFullUrl(this.resourceName, detail1TenderStatUrl), {
      params: {
        year,
        month,
        projectGuid,
        engineeringType
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  getDetail2TenderStat (year, month, projectGuid, engineeringType = '土建') {
    return axios.get(utility.getRestFullUrl(this.resourceName, detail2TenderStatUrl), {
      params: {
        year,
        month,
        projectGuid,
        engineeringType
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  getDetail3TenderStat (year, month, projectGuid, engineeringType = '土建') {
    return axios.get(utility.getRestFullUrl(this.resourceName, detail3TenderStatUrl), {
      params: {
        year,
        month,
        projectGuid,
        engineeringType
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  deleteAll (tenderGuid, year, month) {
    return axios.delete(utility.getRestFullUrl(this.resourceName, 'deleteAll', tenderGuid, year, month), {
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }
}

const g01Service = new G01Service(resourceName)

export default g01Service
