import BaseService from '@/services/BaseService'
import axios from 'axios'
import utility from '@/common/utility'
import auth from '@/common/auth'

let resourceName = 'meteragelist'

let treeListUrl = 'TreeList'
let allTreeListUrl = 'AllTreeList'
let batchAddUrl = 'BatchAddMeterageLists'
let listByTenderGuidUrl = 'ListByTenderGuid'
let allListByTenderGuidUrl = 'AllListByTenderGuid'
let changeDirectiveListByTenderGuidUrl = 'ChangeDirectivesListByTenderGuid'
let syncAccountListUrl = 'MeterageListsToAccountLists'
let allUrl = 'all'

class MeterageListService extends BaseService {
  treeList (tenderGuid) {
    return axios.get(utility.getFullUrl(this.resourceName, treeListUrl), {
      params: {
        tenderGuid
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  allTreeList (tenderGuid) {
    return axios.get(utility.getFullUrl(this.resourceName, allTreeListUrl), {
      params: {
        tenderGuid
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  batchAdd (meterageListModels) {
    meterageListModels.forEach(meterageListModel => {
      meterageListModel.generatePrimaryKey()
    })
    return axios.post(utility.getFullUrl(this.resourceName, batchAddUrl), meterageListModels, {
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  listByTenderGuid (tenderGuid) {
    return axios.get(utility.getFullUrl(this.resourceName, listByTenderGuidUrl), {
      params: {
        tenderGuid
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  allListByTenderGuid (tenderGuid) {
    return axios.get(utility.getFullUrl(this.resourceName, allListByTenderGuidUrl), {
      params: {
        tenderGuid
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  changeDirectiveListByTenderGuid (tenderGuid, periodName) {
    return axios.get(utility.getFullUrl(this.resourceName, changeDirectiveListByTenderGuidUrl), {
      params: {
        tenderGuid,
        periodName
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  syncAccountList (tenderGuid) {
    return axios.post(utility.getFullUrl(this.resourceName, `${syncAccountListUrl}?tenderGuid=${tenderGuid}`), null, {
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  getAll (projectGuid, engineeringType) {
    return axios.get(utility.getRestFullUrl('meterageList', allUrl), {
      params: {
        projectGuid,
        engineeringType
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }
}

const meterageListService = new MeterageListService(resourceName)

export default meterageListService
